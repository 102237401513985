import * as React from "react";
import Layout from "../components/Templates/Layout";
import Meta from "../utils/Meta";

// Markup
const FourOhFourPage = () => {
  const openGraph = {
    type: "website",
    title: "Ooops!",
    description: "Eek!",
  };

  return (
    <Layout>
      <Meta openGraph={openGraph} />
      <section>:-(</section>
    </Layout>
  );
};

export default FourOhFourPage;
